







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconDownload",
  props: {
    fill: {
      type: String,
      default: "white"
    },
  }
})
export default class IconDownload extends Vue {
}
