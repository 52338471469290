

















































































































































































































































































































































































































import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import { Watch } from "vue-property-decorator";
const { v4: uuidv4 } = require('uuid');
const sanitize = require("sanitize-filename");

import BaseComponent from "@/components/base-component.vue";
import Steps from "@/components/steps/steps.vue";
import PhotoPreview from "@/components/photo-preview/photo-preview.vue";
import PaymentOptions from "@/components/payment-options/payment-options.vue";
import Loader from "@/components/loader.vue";
import BaseIcon from "@/components/base-icon/base-icon.vue";
import IconDownload from "@/components/icons/icon-download.vue";
import IconExclamationCircle from "@/components/icons/icon-exclamation-circle.vue";
import { CartState } from "@/store/modules/cart";
import { OrdersState } from "@/store/modules/orders";
import { ArchiveState } from "@/store/modules/archive";
import { InvoicesState } from "@/store/modules/invoices";
import { SettingsState } from "@/store/modules/settings";
import { ProductTypes } from "@/product-types";
import { PaymentMethods } from "@/payment-methods";
import { SBP } from "@/SBP";
import Common from "@/plugins/common";

@Component({
  name: "OrderPaid",
  components: {
    Steps,
    PhotoPreview,
    PaymentOptions,
    Loader,
    BaseIcon,
    IconDownload,
    IconExclamationCircle,
  },
})
export default class OrderPaid extends BaseComponent {

  private readonly cartState: CartState = getModule(CartState, this.$store);
  private readonly ordersState: OrdersState = getModule(OrdersState, this.$store);
  private readonly archiveState: ArchiveState = getModule(ArchiveState, this.$store);
  private readonly invoicesState: InvoicesState = getModule(InvoicesState, this.$store);
  private readonly settingsState: SettingsState = getModule(SettingsState, this.$store);

  orderId: string = "";
  orderAmount: number = 0;
  paymentId: string = "";

  productTypes: any = ProductTypes;
  products: any[] = [];
  emptyProductsCount: number = 0;

  photosDialog: boolean = false;
  photos: any[] = [];
  preorderPhotos: any[] = [];
  photosIndex: number = 0;
  productIndex: number = 0;
  
  archiveId: string = "";
  archiveSize: number = 0;
  isCreatingArchive: boolean = false;
  isArchiveReady: boolean = false;
  isArchiveButtonVisible: boolean = true;
  isReloading: boolean = false;
  isRedirecting: boolean = false;

  eventArchiveId: string = "";
  isCreatingEventArchive: boolean = false;
  isEventArchiveReady: boolean = false;
  isEventArchiveReloading: boolean = false;
  eventArchiveReloadCount: number = 0;
  
  reloadCount: number = 0;
  max_reload_count: number = 100;
  reload_count_first: number = 10;
  reload_count_second: number = 30;
  reload_count_third: number = 60;

  loading: boolean = true;
  isOrderPaid: boolean = false;
  isPreorder: boolean = false;
  isPaymentDeclined: boolean = false;
  showPaymentOptions: boolean = false;

  paymentMethods: any[] = [];
  methodsDescription: any[] = PaymentMethods;
  selectedMethod: string = "";

  steps: any[] = [
    {
      label: "steps/order",
      state: "checked",
    },
    {
      label: "steps/payment",
      state: "active",
    },
  ];

  get items(): any[] {
    return this.products;
  }

  get preorderItems(): any[] {
    return this.preorderPhotos;
  }

  get orderNum(): string {
    if (!this.ordersState.order) return "";
    if (!this.ordersState.order.number) return "";
    return this.ordersState.order.number.toString();
  }

  get customerEmail(): string {
    if (!this.ordersState.order) return "";
    if (!this.ordersState.order.customer) return "";
    if (!this.ordersState.order.customer.email) return "";
    return this.ordersState.order.customer.email.toString();
  }

  get theme(): any {
    return this.settingsState.theme;
  }

  get tenantName(): any {
    return this.settingsState.tenantName;
  }

  get isMySportUSA(): boolean {
    return this.settingsState.isMySportUSA === true;
  }

  get lang(): string {
    return this.settingsState.lang;
  }

  getOrderPaidClasses(): string[] {
    const classes: string[] = [];
    if (!this.isOrderPaid) {
      classes.push('order-paid--state');
      
      if (!this.smOnly) {
        classes.push('order-paid--state-large');
      }
      if (this.smOnly && this.isPaymentDeclined) {
        classes.push('order-paid--declined');
      }
      if (!this.smOnly && this.isPaymentDeclined) {
        classes.push('order-paid--declined-large');
      }
    }
    return classes;
  }

  getItemImage(item: any): string {
    const type: string = item.product.productType;
    if (type.length == 0) return "";

    if (type == this.productTypes.photos_with_me) {
      return "/img/all-photos.svg";
    } else if (type == this.productTypes.photosWithFrame) {
      return "/img/all-photos-in-frame.svg";
    } else if (type == this.productTypes.promocode) {
      return "/img/promocode.svg";
    } else {
      const url: string = item.product.photo.resources.preview;
      if (url.length == 0) return "";
      
      return url;
    }
  }

  getItemTitle(item: any): string {
    const type: string = item.product.productType;
    if (type.length == 0) return "";

    if (type == this.productTypes.photos_with_me) {
      return this.$t('productPreview/productAllPhotos').toString();
    } else if (type == this.productTypes.photosWithFrame) {
      return item.product.title;
    } else if (type == this.productTypes.photo) {
      return this.$t('productPreview/productDigitalPhoto').toString();
    } else if (type == this.productTypes.promocode) {
      return item.product.title;
    } else {
      return this.$t('searchResults/productPhotoFrame').toString();
    }
  }

  getItemEventTitle(item: any): string {
    const catalog: any = item.catalog;
    if (!catalog) return "";

    return catalog.title;
  }

  getItemsOfType(items: any[], type: string): any[] {
    if (type.length == 0) return [];

    let found: any[] = [];
    for (let i = 0; i < items.length; i++) {
      if (!items[i].product) continue;
      if (items[i].product.productType == type) {
        found.push(items[i]);
      }
    }
    return found;
  }

  formatAmount(amount: number, onlyInteger: boolean = true): string {
    return Common.formatAmount(amount, onlyInteger);
  }

  setSelectedMethod(index: number): void {
    if (this.isReloading) return;

    if (index < 0 || index > this.paymentMethods.length-1) {
      return;
    }

    this.selectedMethod = this.paymentMethods[index].id;
  }

  getGroupedByEvent(items: any[]): any[] {
    const grouped: any[] = [];

    for (let item = 0; item < items.length; item++) {
      const eventId: string = items[item].catalog.catalogId;
      const eventName: string = items[item].catalog.title;

      if (eventId == "" || eventName == "") continue;

      if (grouped.length == 0) {          
        let i: any = {
          eventId: eventId,
          eventName: eventName,
          items: [],
        };
        i.items.push(items[item]);
        grouped.push(i);
        continue;
      } else {
        const index: number = grouped.findIndex((r: any) => 
          r.eventId == eventId
        );
        if (index >= 0) {
          grouped[index].items.push(items[item]);
        } else {
          let i: any = {
            eventId: eventId,
            eventName: eventName,
            items: [],
          };
          i.items.push(items[item]);
          grouped.push(i);
        }
      }
    }
    
    return grouped;    
  }

  isItemPromocode(item: any): boolean {
    return item ? item.product.productType === this.productTypes.promocode : false;
  }

  hasOnlyPromocodeItems(): boolean {
    if (this.isPreorder) return false;
    return this.items.length === this.items.filter((i: any) => i.data.product.productType === this.productTypes.promocode).length;
  }

  createCSVLink(products: any[]): string {
    let content = '';
    for (let i = 0; i < products.length; i += 1) {
      content += `${products[i].product.promocode}\n`;
    }
    if (content) {
      const params = "text/csv;charset=UTF-8";
      const blob = new Blob([content], { type: params });
      return URL.createObjectURL(blob);
    }
    return '';
  }

  @Watch("lang")
  async onLangChanged(): Promise<void> {
    await this.updatePaymentTypes();
  }

  async updatePaymentTypes(): Promise<void> {
    await this.invoicesState.getPaymentTypes({ 
      amount: this.ordersState.order.totalAmount, 
      currencyId: this.ordersState.order.currencyId,
      albumId: await this.getAlbumIds(),
    });

    const paymentTypes: any[] = this.invoicesState.paymentTypes;
    const types: any[] = [];

    if (paymentTypes.length > 0) {
      for (let i = 0; i < paymentTypes.length; i += 1) {
        const found: any[] = this.methodsDescription.filter((r: any) => r.id === paymentTypes[i].paymentTypeId);
        
        const item: any = {
          id: paymentTypes[i].paymentTypeId,
          priority: paymentTypes[i].priority,
          title: paymentTypes[i].title,
          description: '',
          discountBadge: '',
          selected: false,
        }
        if (found.length == 0) {
          item.description = '';
        } else {
          item.title = this.$t(found[0].title).toString();
          item.description = this.$t(found[0].description).toString();
          item.discountBadge = (this.theme === '' && SBP.enabled === true && found[0].discountBadge) || '';
        }
        
        types.push(item);
      }
    }

    if (types.length > 0) {
      let index: number = types.findIndex((r: any) => r.id == 'QuickPayment');
      if (this.theme === '' && this.tenantName !== 'Sporttiming' && !this.isMySportUSA && index >= 0) {
        types[index]['desktop'] = '/img/sbp/short-sbp.svg';
        types[index]['mobile'] = '/img/sbp/short-sbp.svg';
      }

      const found: any[] = types.filter((r: any) => r.id === 'Internal');
      if (found.length !== 0) {
        this.paymentMethods = [];
        this.selectedMethod = 'Internal';
        return;
      }

      this.paymentMethods = types.sort(this.sortByPriority);
      this.selectedMethod = this.invoicesState.lastPaymentMethod ? this.invoicesState.lastPaymentMethod : this.paymentMethods[0].id;
    }

  }

  async getAlbumIds(): Promise<string[]> {
    const albumId: string[] = [];

    await this.cartState.getCart({ cartId: this.orderId });
    
    const items: any[] = this.cartState.cartItems;
    for (let i = 0; i < items.length; i += 1) {
      const id: string = items[i].catalog?.catalogId || "";
      if (id && !albumId.includes(id)) albumId.push(id);
    }
    return albumId;
  }

  sortByPriority(a: any, b: any): number {
    if (a.priority < b.priority) return 1;
    if (a.priority > b.priority) return -1;
    return 0;
  }

  async onHashChanged(): Promise<void> {
    if (window.location.hash.length == 0) {
      this.photosDialog = false;
    } else {
      await this.showPhotoFromHash();
    }
  }

  async getItemPhotos(item: any, onlyPhotoWithMe: boolean = false): Promise<any[]> {
    if (!item.product) return [];
    const type: string = item.product.productType;
    if (type.length === 0) return [];

    if (onlyPhotoWithMe) {
      if (type !== this.productTypes.photos_with_me && type !== this.productTypes.photosWithFrame) {
        return [];
      }
    }

    let photos: any = [];
    if (type === this.productTypes.photos_with_me) {
      photos = Object.assign([], item.product.photos);
    } else if (type === this.productTypes.photosWithFrame) {
      photos = Object.assign([], item.product.photos);
    } else if (type === this.productTypes.promocode) {
      photos = [];
    } else {
      photos = [ item.product.photo ];
    }
    if (photos.length === 0) return [];

    return photos;
  }

  async fillProducts(): Promise<void> {
    const products: any[] = [];
    
    await this.ordersState.getItems({ orderId: this.orderId });
    const items: any[] = this.ordersState.items;

    const photosWithMe: any[] = this.getItemsOfType(items, this.productTypes.photos_with_me);
    for (let i = 0; i < photosWithMe.length; i++) {
      let photos: any[] = await this.getItemPhotos(photosWithMe[i], true);
      products.push({ data: photosWithMe[i], photos: photos });
    }

    const photosWithFrame: any[] = this.getItemsOfType(items, this.productTypes.photosWithFrame);
    for (let i = 0; i < photosWithFrame.length; i++) {
      let photos: any[] = await this.getItemPhotos(photosWithFrame[i], true);
      products.push({ data: photosWithFrame[i], photos: photos });
    }

    const frame: any[] = this.getItemsOfType(items, this.productTypes.frame);
    for (let i = 0; i < frame.length; i++) {
      products.push({ data: frame[i], photos: [] });
    }

    const photo: any[] = this.getItemsOfType(items, this.productTypes.photo);
    for (let i = 0; i < photo.length; i++) {
      products.push({ data: photo[i], photos: [] });
    }

    let promocodes: any[] = this.getItemsOfType(items, this.productTypes.promocode);
    promocodes = this.getGroupedByEvent(promocodes);
    for (let i = 0; i < promocodes.length; i++) {
      products.push({
        data: promocodes[i].items[0], 
        items: promocodes[i].items, 
        photos: [],
        showAll: false,
      });
    }

    const count: number = (photo.length + frame.length) % 3;
    if (count > 0) {
      this.emptyProductsCount = count - 1;
    }

    this.photos = [];
    this.products = products;
  }

  async onCreateNewPayment(): Promise<void> {
    if (this.isReloading) return;

    this.isReloading = true;

    const invoiceId: string = this.ordersState.invoiceId;
    if (invoiceId.length == 0) {
      this.isReloading = false;
      return;
    }

    const payload: any = {
      invoiceId: invoiceId,
      payment: {
        paymentId: this.paymentId,
        paymentTypeId: this.selectedMethod,
        redirectUrl: window.location.origin + "/order/" + this.orderId,
      },
    };
    await this.invoicesState.createPayment(payload);

    if (this.invoicesState.responseStatus !== 200) {
      if (this.selectedMethod === "Internal") {
        await this.ordersState.getOrder({ orderId: this.orderId });

        if (this.ordersState.isCompleted) {
          await this.onOrderPaid();
          this.steps[1].state = 'checked';
          return;
        }
      }
      if (this.invoicesState.responseStatus === 409) {
        this.paymentId = uuidv4();
      }
      this.isReloading = false;
      return;
    }

    const url: string = this.invoicesState.payUrl;
    if (url.length == 0) {
      this.isReloading = false;
      return;
    }

    window.location.href = url;

    this.isReloading = false;
  }

  async onGoToPayment(): Promise<void> {
    if (this.isRedirecting) return;

    this.isRedirecting = true;

    const invoiceId: string = this.ordersState.invoiceId;
    if (invoiceId.length == 0) {
      this.isRedirecting = false;
      return;
    }

    const payload: any = {
      invoiceId: invoiceId,
      payment: {
        paymentId: uuidv4(),
        paymentTypeId: this.selectedMethod,
        redirectUrl: window.location.origin + "/order/" + this.orderId,
      },
    };
    await this.invoicesState.createPayment(payload);

    const url: string = this.invoicesState.payUrl;
    if (url.length == 0) {
      this.isRedirecting = false;
      return;
    }

    this.settingsState.reachGoal('media_pay_from_waiting');
    await this.ordersState.addPendingOrder(this.orderId);

    window.location.href = url;

    this.isRedirecting = false;
  }

  async onChangePaymentType(): Promise<void> {
    this.showPaymentOptions = true;
  }

  async onPhotoSelected(item: any, index: number): Promise<void> {
    if (this.isItemPromocode(item.data)) return;
    this.photos = await this.getPhotoUrls(item);
    if (this.photos.length > index) {
      this.photosIndex = index;
    } else {
      this.photosIndex = 0;
    }
    this.photosDialog = true;
  }

  async onPreorderPhotoSelected(index: number): Promise<void> {
    this.photos = Object.assign([], this.preorderItems);
    if (this.photos.length > index) {
      this.photosIndex = index;
    } else {
      this.photosIndex = 0;
    }
    this.photosDialog = true;
  }

  async onDownloadAll(): Promise<void> {
    if (this.isCreatingArchive || this.isCreatingEventArchive || this.isReloading) return;

    this.isCreatingArchive = true;

    this.settingsState.reachGoal("media_photoarchive_download");

    if (this.isArchiveReady) {
      await this.onReadyForDownload();
      this.isCreatingArchive = false;
      return;
    }

    const items: any[] = await this.getItemsForDownload();
    if (items.length === 0) {
      this.isCreatingArchive = false;
      return;
    }

    await this.createArchive(items);
  }

  async onDownload(index: number): Promise<void> {
    this.settingsState.reachGoal("media_photo_download");
    await this.download(index);
  }

  async onDownloadProduct(item: any): Promise<void> {
    this.photos = await this.getPhotoUrls(item);
    if (this.photos.length !== 1) return;

    this.settingsState.reachGoal("media_photo_download");
    await this.download(0);
  }

  async onDownloadPromocodes(products: any[], item: any): Promise<void> {
    const url = this.createCSVLink(products);
    if (!url) return;

    const link = document.createElement('a');

    let filename: string = `promocodes-${item.data.catalog.catalogId}.csv`;
    link.href = url;
    link.setAttribute("download", filename);

    document.body.appendChild(link);
    link.click();
  }

  async onDownloadAllPhotosProduct(item: any): Promise<void> {
    if (this.isCreatingArchive || this.isCreatingEventArchive || this.isReloading || this.isEventArchiveReloading) return;

    this.eventArchiveId = '';
    this.isEventArchiveReady = false;
    this.isCreatingEventArchive = true;

    this.settingsState.reachGoal("media_photoarchive_download");

    const items: any[] = await this.getItemsForDownload(item);
    if (items.length === 0) {
      this.isCreatingEventArchive = false;
      return;
    }

    await this.createEventArchive(items, item.data.orderItemId);
  }

  async onReadyForDownload(): Promise<void> {
    if (this.archiveId.length === 0) return;

    if (this.archiveState.archive && this.archiveState.archive.url) {
      const url: string = this.archiveState.archive.url;
      window.location.href = url;
    }
  }

  async onEventArchiveReadyForDownload(): Promise<void> {
    if (this.eventArchiveId.length === 0) return;

    if (this.archiveState.eventArchive && this.archiveState.eventArchive.url) {
      const url: string = this.archiveState.eventArchive.url;
      window.location.href = url;
    }
  }

  async onCopyPromocode(promocode: string): Promise<void> {
    navigator.clipboard.writeText(promocode);
  }

  async showPhotoFromHash(): Promise<void> {
    if (window.location.hash.length > 0) {
      const values: string[] = window.location.hash.split(":");
      if (values.length == 2 && values[0].length > 1) {
        let productIndex: number = parseInt(values[0].slice(1));
        let photoIndex: number = parseInt(values[1]);

        if (!this.isPreorder) {
          if (isFinite(productIndex) && isFinite(photoIndex) && this.products.length > productIndex) {
            this.productIndex = productIndex;
            setTimeout(async () => {
                await this.onPhotoSelected(this.products[productIndex], photoIndex);
              },
              250
            );
          }
        } else {
          if (isFinite(photoIndex)) {
            setTimeout(async () => {
                await this.onPreorderPhotoSelected(photoIndex);
              },
              250
            );
          }
        }
      }
    }
  }

  async getItemsForDownload(orderItem: any = null): Promise<string[]> {
    let items: any[] = [];
    if (!this.isPreorder) {
      items = await this.getOrderItemsForDownload(orderItem);
    } else {
      items = await this.getPreorderItemsForDownload(orderItem);
    }
    return items;
  }

  async getOrderItemsForDownload(orderItem: any = null): Promise<string[]> {
    await this.ordersState.getItems({ orderId: this.orderId });
    
    if (this.ordersState.items.length == 0) return [];

    const items: any[] = [];
    
    if (orderItem) {
      let item = {
        type: "folder",
        name: sanitize(orderItem.data.catalog.title),
        items: {},
      };
      const photos: any[] = await this.getItemPhotos(orderItem.data);
      if (photos.length == 0) return [];

      const append: any[] = photos.map((i: any) => {
        const result: any = {};

        result['name'] = `${i.fullname}.jpg`;
        result['resource'] = i.resources.main;

        return result;
      });
      item.items = append;
      
      items.push(item);

      return items;
    }

    for (let i = 0; i < this.ordersState.items.length; i++) {
      const item = this.ordersState.items[i];
      const photos: any[] = await this.getItemPhotos(item);
      if (photos.length == 0) continue;

      const append: any[] = photos.map((i: any) => {
        const result: any = {};
        
        result['name'] = `${i.fullname}.jpg`;
        result['resource'] = i.resources.main;

        return result;
      });
      
      items.push(...append);
    }

    return items;
  }

  async getPreorderItemsForDownload(orderItem: any = null): Promise<string[]> {
    if (this.preorderItems.length == 0) return [];

    const items: any[] = [];

    if (orderItem) {
      // 
    }

    for (let i = 0; i < this.preorderItems.length; i++) {
      const item = this.preorderItems[i];
      const photo: any = Object.assign({}, item.photo);

      const append: any = {
        name: `${photo.fullname}.jpg`,
        resource: photo.resources.main,
      }
      
      items.push(append);
    }

    return items;
  }

  async createArchive(items: any[]): Promise<void> {
    await this.archiveState.createArchive({
      archiveId: this.orderId,
      items: items,
    });
    if (this.archiveState.archiveError) {
      this.isCreatingArchive = false;
      return;
    }

    this.archiveId = this.orderId;
    
    this.isArchiveReady = false;
    this.isReloading = false;
    this.reloadCount = 0;
    this.reloadArchiveReady();
  }

  async createEventArchive(items: any[], archiveId: string): Promise<void> {
    await this.archiveState.createArchive({
      archiveId: archiveId,
      items: items,
    });
    if (this.archiveState.archiveError) {
      this.isCreatingEventArchive = false;
      return;
    }

    this.eventArchiveId = archiveId;
    
    this.isEventArchiveReady = false;
    this.isEventArchiveReloading = false;
    this.eventArchiveReloadCount = 0;
    this.reloadEventArchiveReady();
  }

  async reloadArchiveReady(): Promise<void> {
    this.isReloading = true;
    this.isCreatingArchive = false;

    await this.archiveState.getArchive({ archiveId: this.archiveId });
    
    if (this.archiveState.isArchiveReady) {
      this.isArchiveReady = true;
      this.isReloading = false;
      this.reloadCount = 0;
      this.onReadyForDownload();
      return;
    }

    this.reloadCount++;
    if (this.reloadCount < this.max_reload_count) {
      if (this.$route.name == "order-paid" || this.$route.name == "preorder-paid") {
        setTimeout(async () => await this.reloadArchiveReady(), 3000);
      }
    } else {
      this.isReloading = false;
      this.isArchiveReady = false;
      this.reloadCount = 0;
    }
  }

  async reloadEventArchiveReady(): Promise<void> {
    this.isReloading = true;
    this.isEventArchiveReloading = true;
    this.isCreatingEventArchive = false;

    await this.archiveState.getEventArchive({ archiveId: this.eventArchiveId });
    
    if (this.archiveState.isEventArchiveReady) {
      this.isEventArchiveReady = true;
      this.isEventArchiveReloading = false;
      this.isReloading = false;
      this.eventArchiveReloadCount = 0;
      this.onEventArchiveReadyForDownload();
      return;
    }

    this.eventArchiveReloadCount++;
    if (this.eventArchiveReloadCount < this.max_reload_count) {
      if (this.$route.name == "order-paid" || this.$route.name == "preorder-paid") {
        setTimeout(async () => await this.reloadEventArchiveReady(), 3000);
      }
    } else {
      this.isReloading = false;
      this.isEventArchiveReloading = false;
      this.isEventArchiveReady = false;
      this.eventArchiveReloadCount = 0;
    }
  }

  async getPhotoUrls(item: any): Promise<any[]> {
    const type: string = item.data.product.productType;
    if (type.length == 0) return [];

    let photos: any[] = [];
    if (type == this.productTypes.photos_with_me) {
      photos = Object.assign([], item.data.product.photos);
    } else if (type == this.productTypes.photosWithFrame) {
      photos = Object.assign([], item.data.product.photos);
    } else if (type == this.productTypes.photo) {
      photos = [ item.data.product.photo ];
    } else if (type == this.productTypes.frame) {
      photos = [ item.data.product.photo ];
    }
    if (photos.length == 0) return [];

    return photos;
  }

  async download(photoIndex: number): Promise<void> {
    await this.downloadAsFile(this.photos[photoIndex]);
  }

  async downloadAsFile(photo: any): Promise<void> {
    if (!photo) return;

    const thePhoto = !this.isPreorder ? photo : Object.assign({}, photo.photo);

    await this.archiveState.downloadFile({ url: thePhoto.resources.main });
    if (!this.archiveState.file) return;

    const blob = new Blob([this.archiveState.file], { type: "image/jpeg" });
    const url = URL.createObjectURL(blob);
    
    const link = document.createElement('a');

    const filename: string = `${thePhoto.fullname}.jpg`;
    link.href = url;
    link.setAttribute("download", filename);

    document.body.appendChild(link);
    link.click();
  }

  async reloadOrderPaid(): Promise<void> {
    this.isReloading = true;

    if (this.isRedirecting) {
      this.isReloading = false;
      this.reloadCount = 0;
      return;
    }

    await this.ordersState.getOrder({ orderId: this.orderId });

    if (this.ordersState.isCompleted) {
      this.isReloading = false;
      this.reloadCount = 0;
      await this.onOrderPaid();
      return;
    }

    await this.invoicesState.getPayments({ invoiceId: this.orderId });      

    if (this.invoicesState.lastPaymentDeclined) {
      this.isOrderPaid = false;
      this.isPaymentDeclined = true;
      this.isReloading = false;
      this.reloadCount = 0;
      return;
    }

    this.reloadCount++;
    let interval = 1000;
    if (this.reloadCount < this.reload_count_first) {
      interval = 1000;
    } else if (this.reloadCount < this.reload_count_second) {
      interval = 3000;
    } else if (this.reloadCount < this.reload_count_third) {
      interval = 10000;
    } else {
      interval = 20000;
    }

    if (this.$route.name == "order-paid") {
      setTimeout(async () => this.reloadOrderPaid(), interval);
    }
  }

  async onOrderPaid(): Promise<void> {
    await this.cartState.removeCart();
    
    await this.fillProducts();
    
    const items: any[] = this.ordersState.items;
    this.archiveSize = await this.getOrderArchiveSize(items);

    const photosWithMe: any[] = this.getItemsOfType(items, this.productTypes.photos_with_me);
    const photosWithFrame: any[] = this.getItemsOfType(items, this.productTypes.photosWithFrame);
    this.isArchiveButtonVisible = (photosWithMe.length > 0) || (photosWithFrame.length > 0) || (items.length > 1);
    
    if (window.location.hash.length > 0) {
      await this.showPhotoFromHash();
    } else {
      const href = window.location.href;
      if (href[href.length - 1] == "#") {
        window.location.href = href.slice(0, href.length-1);
      }
    }

    this.settingsState.reachGoal('success_order');
    const wasPending = await this.ordersState.deletePendingOrder(this.orderId);
    if (wasPending) {
      await this.ordersState.pushEcommercePurchase();
    }

    this.isOrderPaid = true;
  }

  async onPreorderPaid(): Promise<void> {
    this.archiveSize = await this.getPreorderArchiveSize(this.ordersState.items);
    this.preorderPhotos = this.ordersState.items;
    this.isArchiveButtonVisible = true;
    
    if (window.location.hash.length > 0) {
      await this.showPhotoFromHash();
    } else {
      const href = window.location.href;
      if (href[href.length - 1] == "#") {
        window.location.href = href.slice(0, href.length-1);
      }
    }
    this.isOrderPaid = true;
  }

  async getOrderArchiveSize(items: any[]): Promise<number> {
    let size: number = 0;

    for (let i = 0; i < items.length; i++) {
      const photos: any[] = await this.getItemPhotos(items[i]);
      
      let noSize: boolean = false;

      for (let j = 0; j < photos.length; j++) {
        if (!photos[j].size) {
          noSize = true;
          break;
        }
        size += photos[j].size;
      }

      if (noSize) {
        size = 0;
        break;
      }
    }

    if (size != 0) {
      size = Math.round(size / Math.pow(2, 20));
    }
    
    return size;
  }

  async getPreorderArchiveSize(items: any[]): Promise<number> {
    let size: number = 0;

    for (let i = 0; i < items.length; i++) {
      if (!items[i].photo.size) {
        size = 0;
        break;
      }
      size += items[i].photo.size;
    }

    if (size != 0) {
      size = Math.round(size / Math.pow(2, 20));
    }
    
    return size;
  }

  async backToHome(): Promise<void> {
    this.$store.state.history = [];
    await this.$router.push({ name: "home" });
  }

  async mounted(): Promise<void> {
    this.$store.state.buttonHome = true;
    this.$store.state.buttonBack = false;
    this.$store.state.buttonCart = true;
    this.$store.state.showMenu = true;

    const orderId: string = this.$route.params.id || "";
    if (orderId.length == 0) {
      await this.backToHome();
      return;
    }

    if (this.$router.currentRoute.name === "preorder-paid") {
      this.isPreorder = true;
    }

    if (!this.isPreorder) {
      await this.ordersState.getOrder({ orderId: orderId });
      if (this.ordersState.isOrderError || this.ordersState.isCanceled) {
        await this.backToHome();
        return;
      }
    } else {
      await this.ordersState.getPreorderItems({ preorderId: orderId });
      if (this.ordersState.isOrderError) {
        await this.backToHome();
        return;
      }
    }

    this.orderId = orderId;
    this.orderAmount = this.ordersState.order.totalAmount;

    window.addEventListener('hashchange', this.onHashChanged);

    if (!this.isPreorder) {
      if (this.ordersState.isCompleted) {
        await this.onOrderPaid();
        this.steps[1].state = 'checked';
      } else {
        
        await this.invoicesState.getPayments({ invoiceId: orderId });

        if (this.invoicesState.lastPaymentDeclined) {
          await this.updatePaymentTypes();
          this.isOrderPaid = false;
          this.isPaymentDeclined = true;
        } else {
          await this.updatePaymentTypes();
          this.isOrderPaid = false;
          this.reloadOrderPaid();
        }
        
      }
    } else {
      await this.onPreorderPaid();
    }

    this.paymentId =  uuidv4();
    
    this.$store.state.goBack = "";
    this.backToTop();

    this.loading = false;

    this.settingsState.trackPage("order/");
  }

}
