







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconCheckBold",
  props: {
    fill: {
      type: String,
      default: "var(--color-success)"
    }
  },
})
export default class IconCheckBold extends Vue {
}
