<template>
  <div class="product-preview">
    <div class="product-preview__container">
      
      <Row class="container__header">
        <Col class="header__col">
          <div class="header__text">
            {{ $t('productPreview/goods') }}
          </div>
          <Button 
            @click="onCancel" 
            variant="clear"
            view="light"
            shape="circle"
            class="header__button-cancel" 
          >
            <slot name="before">
              <span class="rr-button__icon rr-button__icon_auto">
                <base-icon v-if="theme === ''" width="20" height="20" viewBox="0 0 24 24">
                  <icon-close2 fill="var(--color-black)" opacity="0.85" />
                </base-icon>
                <base-icon v-if="theme !== ''" width="20" height="20" viewBox="0 0 20 20">
                  <icon-close fill="var(--color-black-85)" opacity="1" />
                </base-icon>
              </span>
            </slot>
          </Button>
        </Col>        
      </Row>

      <Row>
        <Col class="container__product-list">
          <div
            v-for="item in items"
            :key="item.data.shoppingCartItemId"
          >
            <div class="product-list__item">
              <div class="d-flex">
                <div 
                  class="item__image"
                  :style="{'background-image': 'url(' + getItemImage(item.data) + ')'}"
                  @click.stop="onSelected(item.data, 0)"
                ></div>
                <div>
                  <div :class="'item__price ' + (isDiscountedPrice(item.data) ? 'price--discounted' : '')">
                    {{ formatAmount(item.data.totalPrice, false) }}
                    <span class="price__original" v-show="isDiscountedPrice(item.data)">
                      {{ formatAmount(item.data.originalPrice, false) }}
                    </span>
                  </div>
                  <div class="item__title text-wrap" v-html="getItemTitle(item.data)">
                  </div>
                </div>
              </div>
              <div v-if="item.photos.length > 0" class="product-list__photos d-flex flex-wrap">
                <div 
                  v-for="(photo, index) in item.photos" :key="index"
                  class="photos__item" 
                >
                  <div :class="(index > 0 && (index+1) % 4 == 0) ? 'item--last' : 'item--normal'">
                    <div 
                      class="item__image item__image--sm"
                      :style="{'background-image': 'url(' + photo.resources.preview + ')'}"
                      @click.stop="onSelected(item.data, index)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

    </div>
  </div>
</template>


<script lang="js">
import { mapGetters } from 'vuex';
import BaseIcon from '@/components/base-icon/base-icon.vue';
import IconClose from '@/components/icons/icon-close.vue';
import IconClose2 from '@/components/icons/icon-close2.vue';
import { ProductTypes } from '@/product-types';
import Common from '@/plugins/common';

export default {
  name: 'ProductPreview',
  props: {
    items_: Array,
  },
  components: {
    BaseIcon,
    IconClose,
    IconClose2,
  },
  data() {
    return {
      productTypes: ProductTypes,
    };
  },
  computed: {
    ...mapGetters('SettingsState', ['theme']),
    items() {
      return this.$props.items_;
    },
  },
  methods: {
    formatAmount(amount, onlyInteger = true) {
      return Common.formatAmount(amount, onlyInteger);
    },
    getItemImage(item) {
      const type = item.product.productType;
      if (type.length == 0) return '';

      if (type == this.productTypes.photos_with_me) {
        return '/img/all-photos.svg';
      } else if (type == this.productTypes.photosWithFrame) {
        return '/img/all-photos-in-frame.svg';
      } else if (type == this.productTypes.promocode) {
        return '/img/promocode.svg';
      } else {
        const url = item.product.photo.resources.preview;
        if (url.length == 0) return '';
        
        return url;
      }
    },
    getItemTitle(item) {
      const type = item.product.productType;
      if (type.length == 0) return '';

      if (type == this.productTypes.photos_with_me) {
        return this.$t('productPreview/productAllPhotos').toString();
      } else if (type == this.productTypes.photosWithFrame) {
        return item.product.title;
      } else if (type == this.productTypes.photo) {
        return this.$t('productPreview/productDigitalPhoto').toString();
      } else if (type == this.productTypes.promocode) {
        return item.product.title;
      } else {
        return this.$t('searchResults/productPhotoFrame').toString();
      }
    },
    isDiscountedPrice(item) {
      return item.originalPrice != item.totalPrice;
    },
    onSelected(item, index) {
      if (item.product.productType === this.productTypes.promocode) return;
      this.$emit('selected', { item: item, index: index });
    },
    onCancel() {
      this.$emit('cancelled');
    },
  },
};
</script>


<style lang="scss" scoped>
  @import "./product-preview";
</style>