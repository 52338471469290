







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconPlus",
  props: {
    fill: {
      type: String,
      default: "var(--color-primary)"
    },
  },
})
export default class IconPlus extends Vue {
}
