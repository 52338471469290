import { render, staticRenderFns } from "./product-list.vue?vue&type=template&id=a8ae36ba&scoped=true&"
import script from "./product-list.vue?vue&type=script&lang=js&"
export * from "./product-list.vue?vue&type=script&lang=js&"
import style0 from "./product-list.vue?vue&type=style&index=0&id=a8ae36ba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8ae36ba",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
installComponents(component, {VDialog,VSlideGroup,VSlideItem})
