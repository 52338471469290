export const PaymentMethods = [
  {
    id: "CreditCard",
    typeId: "CreditCard",
    title: "order/methodCreditCardTitle",
    description: "order/methodCreditCardDescription",
    disabledFor: ['Sporttiming'],
  },
  {
    id: "CreditCardSporttimingBel",
    typeId: "CreditCard",
    title: "order/methodCreditCardBelTitle",
    description: "order/methodCreditCardBelDescription",
    enabledFor: ['Sporttiming'],
  },
  {
    id: "CreditCardSporttimingRus",
    typeId: "CreditCard",
    title: "order/methodCreditCardRusTitle",
    description: "order/methodCreditCardRusDescription",
    enabledFor: ['Sporttiming'],
  },
  {
    id: "QuickPayment",
    typeId: "QuickPayment",
    title: "order/methodQuickPaymentTitle",
    description: "order/methodQuickPaymentDescription",
    discountBadge: "order/methodQuickPaymentDiscount",
    disabledFor: ['Sporttiming'],
  },
];
