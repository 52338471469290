







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconEmail",
  props: {
    fill: {
      type: String,
      default: "white"
    },
  },
})
export default class IconEmail extends Vue {
}
