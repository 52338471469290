<template>
  <div class="steps">
    <div
      v-for="(item, i) in items"
      :key="i"
      class="steps__item"
    >
      <div class="steps__step">
        <div class="step__icon" :class="getIconClasses(item.state)">
          <span v-if="item.state !== 'checked'">{{ i + 1 }}</span>
          <span v-if="item.state === 'checked'" style="position: relative; top: 2px;">
            <base-icon width="12" height="12">
              <icon-check-bold fill="currentColor" />
            </base-icon>
          </span>
        </div>
        <div class="step__label" :class="item.state === 'active' ? 'step__label--active' : ''">
          {{ $t(item.label) }}
        </div>
      </div>
      <div v-if="i < items.length - 1" class="steps__line"></div>
    </div>
  </div>
</template>


<script lang="js">
import BaseIcon from "@/components/base-icon/base-icon.vue";
import IconCheckBold from "@/components/icons/icon-check-bold.vue";

export default {
  name: 'Steps',
  props: {
    items: Array,
  },
  components: {
    BaseIcon,
    IconCheckBold,
  },
  methods: {
    getIconClasses(state) {
      const classes = [];
      if (state === 'active') classes.push('step__icon--active');
      if (state === 'checked') classes.push('step__icon--checked');
      return classes;
    },
  },
};
</script>


<style lang="scss" scoped>
  @import "./steps";
</style>
